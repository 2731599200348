
import { mapActions } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import Firebase from '@/plugins/firebase.service';
import AlertError from '@/components/alert-error/alert-error.component.vue';
import { AxiosResponse, AxiosError } from 'axios';
import LoginService from './services/login.service';
import RenderApp from '../../services/render.service';

@Component({
  components: {
    AlertError,
  },
  methods: {
    ...mapActions(['setToken', 'setIsAuthenticated']),
  },
})
export default class LoginAPp extends Vue {
  private email: string = '';

  private password: string = '';

  private errors: any = {};

  private setToken!: Function;

  private setIsAuthenticated!: Function;

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private setError(err: any) {
    if (!err.response.data.errors) {
      this.errors = { acesso: [err.response.data.message] };
      return;
    }

    this.errors = err.response.data.errors;
  }

  private async auth() {
    LoginService.authenticate(this.email, this.password)
      .then(async (data) => {
        this.setToken(data.access_token);
        await RenderApp.getRequireInfo().finally(() => {
          this.setIsAuthenticated(true);
          Vue.use(Firebase);
          this.$router.push({ name: 'home' });
        });
      })
      .catch((err: any) => {
        this.setError(err);
      });
  }

  private forgotPassword() {
    this.$router.push({
      name: 'reset-password',
    });
  }

  private created() {
    this.$loader.close();
    this.setIsAuthenticated(false);
    window.localStorage.clear();
  }
}
